import { DropdownItem } from '@/models/dropdown';

export const FirstSixMonths = ['january', 'february', 'march', 'april', 'may', 'june'];

export const LastSixMonths = ['july', 'august', 'september', 'october', 'november', 'december'];

export const weekDaysDropdown: DropdownItem[] = [
  {
    text: `Monday`,
    value: `Monday`,
    uniqueKey: `Monday_1`,
  },
  {
    text: `Tuesday`,
    value: `Tuesday`,
    uniqueKey: `Tuesday_1`,
  },
  {
    text: `Wednesday`,
    value: `Wednesday`,
    uniqueKey: `Wednesday_1`,
  },
  {
    text: `Thursday`,
    value: `Thursday`,
    uniqueKey: `Thursday_1`,
  },
  {
    text: `Friday`,
    value: `Friday`,
    uniqueKey: `Friday_1`,
  },
  {
    text: `Saturday`,
    value: `Saturday`,
    uniqueKey: `Saturday_1`,
  },
  {
    text: `Sunday`,
    value: `Sunday`,
    uniqueKey: `Sunday_1`,
  },
];

export const monthDurationDropdown: DropdownItem[] = [
  {
    text: `0`,
    value: `0`,
    uniqueKey: `0`,
  },
  {
    text: `1`,
    value: `1`,
    uniqueKey: `1`,
  },
  {
    text: `2`,
    value: `2`,
    uniqueKey: `2`,
  },
  {
    text: `3`,
    value: `3`,
    uniqueKey: `3`,
  },
  {
    text: `4`,
    value: `4`,
    uniqueKey: `4`,
  },
  {
    text: `5`,
    value: `5`,
    uniqueKey: `5`,
  },
  {
    text: `6`,
    value: `6`,
    uniqueKey: `6`,
  },
  {
    text: `7`,
    value: `7`,
    uniqueKey: `7`,
  },
  {
    text: `8`,
    value: `8`,
    uniqueKey: `8`,
  },
  {
    text: `9`,
    value: `9`,
    uniqueKey: `9`,
  },
  {
    text: `10`,
    value: `10`,
    uniqueKey: `10`,
  },
  {
    text: `11`,
    value: `11`,
    uniqueKey: `11`,
  },
  {
    text: `12`,
    value: `12`,
    uniqueKey: `12`,
  },
];

export const weekDurationDropdown: DropdownItem[] = [
  {
    text: `0`,
    value: `0`,
    uniqueKey: `0`,
  },
  {
    text: `1`,
    value: `1`,
    uniqueKey: `1`,
  },
  {
    text: `2`,
    value: `2`,
    uniqueKey: `2`,
  },
  {
    text: `3`,
    value: `3`,
    uniqueKey: `3`,
  },
  {
    text: `4`,
    value: `4`,
    uniqueKey: `4`,
  },
  {
    text: `5`,
    value: `5`,
    uniqueKey: `5`,
  },
];

export const MaximumMCQInputs = 8;
export const MinimumMCQInputs = 4;

export const OneOrMultipleAnswers: DropdownItem[] = [
  {
    text: `One answer only`,
    value: `one`,
    uniqueKey: `one_answer`,
  },
  {
    text: `Multiple answers`,
    value: `multiple`,
    uniqueKey: `multiple_answer`,
  },
];

export const NumberChoices: DropdownItem[] = [
  {
    text: `a., b., c., ...`,
    value: `abc`,
    uniqueKey: `abc_selection`,
  },
  {
    text: `A., B., C., ...`,
    value: `ABCD`,
    uniqueKey: `capital_alphabets_selection`,
  },
  {
    text: `1., 2., 3., ...`,
    value: `123`,
    uniqueKey: `numeric_selection`,
  },
  {
    text: `i., ii., iii., ...`,
    value: `iii`,
    uniqueKey: `roman_selection`,
  },
  {
    text: `I., II., III., ...`,
    value: `iii`,
    uniqueKey: `capital_roman_selection`,
  },
  {
    text: `No numbering`,
    value: `none`,
    uniqueKey: `no_selection`,
  },
];

export const BasicYesOrNo: DropdownItem[] = [
  {
    text: `Yes`,
    value: `yes`,
    uniqueKey: `yes`,
  },
  {
    text: `No`,
    value: `no`,
    uniqueKey: `no`,
  },
];

// export const AcceptedImageFileType: string[] = [`image/png`, `image/gif`, `image/jpeg`];
export const AcceptedImageFileType: string[] = [`image/png`, `image/jpeg`];

export const AcceptedVideoFileType: string[] = [`video/mp4`];

export const WeekDaysToNumberMap = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 6,
};

export const NumberToWeekDaysMap = {
  0: `Monday`,
  1: `Tuesday`,
  2: `Wednesday`,
  3: `Thursday`,
  4: `Friday`,
  5: `Saturday`,
  6: `Sunday`,
};

export const WeekDaysObjectArray = [
  {
    id: 0,
    name: `Monday`,
  },
  {
    id: 1,
    name: `Tuesday`,
  },
  {
    id: 2,
    name: `Wednesday`,
  },
  {
    id: 3,
    name: `Thursday`,
  },
  {
    id: 4,
    name: `Friday`,
  },
  {
    id: 5,
    name: `Saturday`,
  },
  {
    id: 6,
    name: `Sunday`,
  },
];

export enum Roles {
  Teacher = `teacher`,
  Admin = `admin`,
}

export enum RouteCategories {
  timetable = `Timetable`,
  courses = `Course Management`,
  questions = `Question Bank Management`,
  user = `Users`,
  users = `User Management`,
  homeworks = `Homework Management`,
  resources = `Resources`,
  orders = `Orders`,
  progress = `Students' Progress Tracker`,
  dashboard = `Dashboard`,
  settings = `Settings`,
  promotion = `Promotion`,
  events = `Event Management`,
  banners = `Banner Management`,
  rewards = `Reward Management`,
  badges = `Badge Management`,
  notificationSettings = `Notification Settings`,
  notificationBatches = `Notification Batches`,
}

export enum ReadingMaterialFileType {
  pptx = `pptx`,
  docx = `docx`,
  doc = `doc`,
  xlsx = `xlsx`,
  pdf = `pdf`,
  png = `png`,
  jpeg = `jpeg`,
  jpg = `jpg`,
}

export enum QuestionTypeNames {
  mcq = `Mcq`,
  essay = `Essay`,
}

export const QuestionTypeDataSelection = [
  {
    type: `Mcq`,
    name: `MCQ`,
  },
  {
    type: `Essay`,
    name: `Subjective`,
  },
];

export const RequireTextDropdownValues: DropdownItem[] = [
  {
    text: `Require the student to enter text`,
    value: `1`,
    uniqueKey: `require_text`,
  },
  {
    text: `Text input is optional`,
    value: `0`,
    uniqueKey: `optional_text`,
  },
];

export const PAGINATION_LIMITS = [10, 20, 30];
export enum OrderStatus {
  paid = `Paid`,
  pending = `Pending`,
  terminated = `Terminated`,
  failed = `Failed`,
}

export enum VideoFileSizeLimit {
  course = 100,
  questions = 5,
}

export const repeatTypesDropdown: DropdownItem[] = [
  {
    text: `Never`,
    value: `never`,
    uniqueKey: `never`,
  },
  {
    text: `Daily`,
    value: `daily`,
    uniqueKey: `daily`,
  },
  {
    text: `Weekly`,
    value: `weekly`,
    uniqueKey: `weekly`,
  },
  {
    text: `Monthly`,
    value: `monthly`,
    uniqueKey: `monthly`,
  },
  {
    text: `Yearly`,
    value: `yearly`,
    uniqueKey: `yearly`,
  },
];
