import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { UserResponseListItem } from '@/models/users';

@Module({
    namespaced: true,
    dynamic: true,
    name: `user.state`,
    store,
})
class UserStateStore extends VuexModule {
    isLoading = false;
    isModified: boolean | null = null;
    error: any | null = null;
    usersData: UserResponseListItem[] = [];
    currentPageState: number | null = null;
    currentLimitState: number | null = null;
    currentKeywordState: string | null = null;
    currentTotalCountState: number | null = null;
    currentSortByState: string | null = null;
    currentSortOrderState: string | null = null;
    currentFilterState: string | null = null;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    setIsModified(payload: boolean | null) {
        this.isModified = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    saveUserData(payload: UserResponseListItem[]) {
        this.usersData = payload;
    }

    @Mutation
    saveUserPageState(payload: number | null) {
        this.currentPageState = payload;
    }

    @Mutation
    saveUserLimitState(payload: number | null) {
        this.currentLimitState = payload;
    }

    @Mutation
    saveUserKeywordState(payload: string | null) {
        this.currentKeywordState = payload;
    }

    @Mutation
    saveUserTotalState(payload: number | null) {
        this.currentTotalCountState = payload;
    }

    @Mutation
    saveUserSortByState(payload: string | null) {
        this.currentSortByState = payload;
    }

    @Mutation
    saveUserSortOrderState(payload: string | null) {
        this.currentSortOrderState = payload;
    }

    @Mutation
    saveUserFilterState(payload: string | null) {
        this.currentFilterState = payload;
    }

    // Actions

    @Action
    updateUserIsModified(payload: boolean | null) {
        this.context.commit('setIsModified', payload);
    }

    @Action
    updateUsersData(payload: UserResponseListItem[]) {
        this.context.commit(`saveUserData`, payload);
    }

    @Action
    updateUserPageState(payload: number | null ) {
        this.context.commit(`saveUserPageState`, payload);
    }

    @Action
    updateUserLimitState(payload: number | null) {
        this.context.commit(`saveUserLimitState`, payload);
    }

    @Action
    updateUserKeywordState(payload: string | null) {
        this.context.commit(`saveUserKeywordState`, payload);
    }

    @Action
    updateUserTotalState(payload: number | null) {
        this.context.commit(`saveUserTotalState`, payload);
    }

    @Action
    updateUserSortByState(payload: string | null ) {
        this.context.commit(`saveUserSortByState`, payload);
    }

    @Action
    updateUserSortOrderState(payload: string | null ) {
        this.context.commit(`saveUserSortOrderState`, payload);
    }

    @Action
    updateUserFilterState(payload: string | null ) {
        this.context.commit(`saveUserFilterState`, payload);
    }

    @Action
    resetState() {
        this.updateUserIsModified(null);
        this.updateUsersData([]);
        this.updateUserPageState(null);
        this.updateUserLimitState(null);
        this.updateUserSortByState(null);
        this.updateUserTotalState(null);
        this.updateUserKeywordState(null);
        this.updateUserSortOrderState(null);
        this.updateUserFilterState(null);
    }

}

export default getModule(UserStateStore);