import { UserStores } from '@/store/users';
import { QuestionTypeNames, Roles, RouteCategories } from '@/utils/constants';
import { ToastProgrammatic } from 'buefy';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { RouteConfigSingleView } from 'vue-router/types/router';

Vue.use(VueRouter);

interface PageProps {
  path: string;
}

const createRoute = ({
  listPage,
  createPage,
  editPage,
  name,
  category,
}: {
  listPage?: PageProps;
  createPage?: PageProps;
  editPage?: PageProps;
  name: string;
  category: RouteCategories;
}) => {
  const pages = [];

  if (listPage) {
    pages.push({
      name,
      path: `/${name}`,
      exact: true,
      component: () => import(/* webpackPrefetch: true */ `@/pages/${listPage.path}`),
      meta: {
        requiresAuth: false,
        roles: [Roles.Admin],
        category,
        title: `Tavis | ${category}`,
      },
    });
  }

  if (createPage) {
    pages.push({
      name: `create_${name}`,
      path: `/${name}/create`,
      exact: true,
      component: () => import(/* webpackPrefetch: true */ `@/pages/${createPage.path}`),
      meta: {
        requiresAuth: true,
        roles: [Roles.Admin],
        category,
        title: `Tavis | Create ${category}`,
      },
    });
  }

  if (editPage) {
    pages.push({
      name: `edit_${name}`,
      path: `/${name}/:id/edit`,
      exact: true,
      component: () => import(/* webpackPrefetch: true */ `@/pages/${editPage.path}`),
      meta: {
        requiresAuth: true,
        roles: [Roles.Admin],
        category,
        title: `Tavis | Edit ${category}`,
      },
    });
  }

  return pages;
};

export const routes = [
         {
           path: '/',
           redirect: 'timetable',
         },
         {
           path: '/timetable',
           name: 'timetable',
           exact: true,
           component: () => import(/* webpackPrefetch: true */ '@/pages/timetable/Timetable.vue'),
           meta: {
             requiresAuth: true,
             roles: [Roles.Teacher, Roles.Admin],
             category: RouteCategories.timetable,
             title: `Tavis | Timetable`,
           },
         },
         {
           path: '/courses',
           name: 'courses',
           exact: true,
           component: () => import(/* webpackPrefetch: true */ '@/pages/courses/Courses.vue'),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.courses,
             title: `Tavis | Courses`,
           },
         },
         {
           name: 'create_courses',
           path: '/courses/create',
           exact: true,
           component: () => import(/* webpackPrefetch: true */ '@/pages/courses/AddCourses.vue'),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.courses,
             title: `Tavis | Create Courses`,
           },
         },
         {
           name: 'edit_courses',
           path: '/courses/:id/edit',
           exact: true,
           component: () => import(/* webpackPrefetch: true */ `@/pages/courses/EditCourses.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.courses,
             title: `Tavis | Edit Courses`,
           },
         },
         {
           name: 'question_banks',
           path: '/question_banks',
           exact: true,
           component: () =>
             import(/* webpackPrefetch: true */ `@/pages/question_banks/QuestionBanks.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.questions,
             title: `Tavis | Question Bank`,
           },
         },
         {
           name: 'create_question_banks',
           path: '/question_banks/create',
           exact: true,
           component: () =>
             import(/* webpackPrefetch: true */ `@/pages/question_banks/AddQuestionBank.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.questions,
             title: `Tavis | Create Question Bank`,
           },
         },
         {
           name: 'edit_question_banks',
           path: '/question_banks/:id/edit',
           exact: true,
           component: () =>
             import(/* webpackPrefetch: true */ `@/pages/question_banks/EditQuestionBank.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.questions,
             title: `Tavis | Edit Question Bank`,
           },
         },
         {
           name: 'question_banks_listing',
           path: '/question_banks/listing',
           exact: true,
           component: () =>
             import(/* webpackPrefetch: true */ `@/pages/question_banks/QuestionBankListing.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.questions,
             title: `Tavis | Question Bank Listing`,
           },
         },
         {
           name: 'create_questions',
           path: '/questions/create',
           exact: true,
           component: () =>
             import(/* webpackPrefetch: true */ `@/pages/question_banks/AddQuestion.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.questions,
             title: `Tavis | Create Question Bank Listing`,
           },
         },
         {
           name: 'edit_questions',
           path: '/questions/:id/edit',
           exact: true,
           component: () =>
             import(/* webpackPrefetch: true */ `@/pages/question_banks/EditQuestion.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.questions,
             title: `Tavis | Edit Question Bank Listing`,
           },
         },
         {
           name: 'import_questions',
           path: '/questions/import',
           exact: true,
           component: () =>
             import(/* webpackPrefetch: true */ `@/pages/question_banks/ImportQuestions.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.questions,
             title: `Tavis | Question Bank`,
           },
         },
         {
           name: 'users',
           path: '/users',
           exact: true,
           component: () => import(/* webpackPrefetch: true */ `@/pages/users/Users.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.users,
             title: `Tavis | Users`,
           },
         },
         {
           name: 'create_users',
           path: '/users/create',
           exact: true,
           component: () => import(/* webpackPrefetch: true */ `@/pages/users/CreateUsers.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.users,
             title: `Tavis | Create Users`,
           },
         },
         {
           name: 'edit_users',
           path: '/users/:id/edit',
           exact: true,
           component: () => import(/* webpackPrefetch: true */ `@/pages/users/EditUsers.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.users,
             title: `Tavis | Edit Users`,
           },
         },
         {
           name: 'view_users',
           path: '/users/:id/view',
           exact: true,
           component: () => import(/* webpackPrefetch: true */ `@/pages/users/ViewUsers.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.user,
             title: `Tavis | Users`,
           },
         },
         {
           name: 'view_profile',
           path: '/profile',
           exact: true,
           component: () => import(/* webpackPrefetch: true */ `@/pages/users/ViewProfile.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin, Roles.Teacher],
             category: RouteCategories.user,
             title: `Tavis | User Profile`,
           },
         },
         {
           name: 'reset_password',
           path: '/reset_password',
           component: () => import(/* webpackPrefetch: true */ `@/pages/login/ResetPassword.vue`),
           meta: {
             requiresAuth: false,
             title: `Tavis | Reset Password`,
           },
         },
         {
           name: 'reset_password_success',
           path: '/reset_password_success',
           component: () =>
             import(/* webpackPrefetch: true */ `@/pages/login/ResetPasswordSuccessPage.vue`),
           meta: {
             requiresAuth: false,
             title: `Tavis | Reset Password`,
           },
         },
         {
           name: 'login',
           path: '/login',
           exact: true,
           component: () => import(/* webpackPrefetch: true */ `@/pages/login/Login.vue`),
           meta: {
             requiresAuth: false,
             title: `Tavis | Login`,
           },
         },
         {
           name: 'homeworks',
           path: '/homeworks',
           exact: true,
           component: () => import(/* webpackPrefetch: true */ `@/pages/homework/Homework.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.homeworks,
             title: `Tavis | Homeworks`,
           },
         },
         {
           name: 'create_homeworks',
           path: '/homeworks/create',
           exact: true,
           component: () =>
             import(/* webpackPrefetch: true */ `@/pages/homework/CreateHomework.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.homeworks,
             title: `Tavis | Create Homeworks`,
           },
         },
         {
           name: 'edit_homeworks',
           path: '/homeworks/:id/edit',
           exact: true,
           component: () => import(/* webpackPrefetch: true */ `@/pages/homework/EditHomework.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.homeworks,
             title: `Tavis | Edit Homeworks`,
           },
         },
         {
           name: 'resources',
           path: '/resources',
           exact: true,
           component: () => import(/* webpackPrefetch: true */ `@/pages/resources/Resources.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.resources,
             title: `Tavis | Resources`,
           },
         },
         {
           name: 'resources_lectures',
           path: '/resources/lectures',
           exact: true,
           component: () => import(/* webpackPrefetch: true */ `@/pages/resources/Lectures.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.resources,
             title: `Tavis | Lectures`,
           },
         },
         {
           name: 'create_resources',
           path: '/resources/create',
           exact: true,
           component: () =>
             import(/* webpackPrefetch: true */ `@/pages/resources/CreateResources.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.resources,
             title: `Tavis | Create Resources`,
           },
         },
         {
           name: 'edit_resources',
           path: '/resources/:id/edit',
           exact: true,
           component: () =>
             import(/* webpackPrefetch: true */ `@/pages/resources/EditResources.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.homeworks,
             title: `Tavis | Edit Resources`,
           },
         },
         {
           name: 'create_lectures',
           path: '/resources/lectures/create',
           exact: true,
           component: () =>
             import(/* webpackPrefetch: true */ `@/pages/resources/CreateLectures.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.resources,
             title: `Tavis | Create Lectures`,
           },
         },
         {
           name: 'edit_lectures',
           path: '/resources/lectures/:id/edit',
           exact: true,
           component: () =>
             import(/* webpackPrefetch: true */ `@/pages/resources/EditLectures.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.resources,
             title: `Tavis | Edit Lectures`,
           },
         },
         {
           name: 'orders',
           path: '/orders',
           exact: true,
           component: () => import(/* webpackPrefetch: true */ `@/pages/orders/Orders.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.orders,
             title: `Tavis | Orders`,
           },
         },
         {
           name: 'progress',
           path: '/progress',
           exact: true,
           component: () => import(/* webpackPrefetch: true */ `@/pages/progress/Students.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.progress,
             title: `Tavis | Student Progress`,
           },
         },
         {
           name: 'student_progress',
           path: '/progress/:id',
           exact: true,
           component: () =>
             import(/* webpackPrefetch: true */ `@/pages/progress/StudentCourses.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.progress,
             title: `Tavis | Student Progress`,
           },
         },
         {
           name: 'student_detailed_progress',
           path: '/progress/:id/courses/:courseId',
           exact: true,
           component: () =>
             import(/* webpackPrefetch: true */ `@/pages/progress/DetailedStudentCourses.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.progress,
             title: `Tavis | Student Progress`,
           },
         },
         {
           name: 'create_homework_questions',
           path: '/homeworks/questions/create',
           exact: true,
           query: {
             type: [QuestionTypeNames.mcq, QuestionTypeNames.essay],
           },
           component: () =>
             import(/* webpackPrefetch: true */ `@/pages/homework/AddHomeworkQuestion.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.homeworks,
             title: `Tavis | Create Homework Question`,
           },
         },
         {
           name: 'edit_homework_questions',
           path: '/homeworks/questions/:id/edit',
           exact: true,
           component: () =>
             import(/* webpackPrefetch: true */ `@/pages/homework/EditHomeworkQuestion.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.homeworks,
             title: `Tavis | Edit Homework Question`,
           },
         },
         {
           name: `dashboard`,
           path: `/dashboard`,
           exact: true,
           component: () => import(/* webpackPrefetch: true */ `@/pages/dashboard/Dashboard.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.dashboard,
             title: `Tavis | Dashboard`,
           },
         },
         {
           name: `settings`,
           path: `/settings`,
           exact: true,
           component: () => import(/* webpackPrefetch: true */ `@/pages/settings/Settings.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.settings,
             title: `Tavis | Settings`,
           },
         },
         ...createRoute({
           category: RouteCategories.events,
           name: 'events',
           listPage: {
             path: `events/Events.vue`,
           },
           createPage: {
             path: `events/AddEvents.vue`,
           },
           editPage: {
             path: `events/EditEvents.vue`,
           },
         }),
         ...createRoute({
           category: RouteCategories.banners,
           name: 'banners',
           listPage: {
             path: `banners/Banners.vue`,
           },
           createPage: {
             path: `banners/AddBanners.vue`,
           },
           editPage: {
             path: `banners/EditBanners.vue`,
           },
         }),
         ...createRoute({
           category: RouteCategories.rewards,
           name: 'rewards',
           listPage: {
             path: `rewards/Rewards.vue`,
           },
           createPage: {
             path: `rewards/AddRewards.vue`,
           },
           editPage: {
             path: `rewards/EditRewards.vue`,
           },
         }),
         ...createRoute({
           category: RouteCategories.badges,
           name: 'badges',
           listPage: {
             path: `badges/Badges.vue`,
           },
           createPage: {
             path: `badges/AddBadges.vue`,
           },
           editPage: {
             path: `badges/EditBadges.vue`,
           },
         }),
         {
           name: 'badge_award',
           path: '/badges/award-user',
           exact: true,
           component: () => import(/* webpackPrefetch: true */ `@/pages/badges/AwardUserBadge.vue`),
           meta: {
             requiresAuth: true,
             roles: [Roles.Admin],
             category: RouteCategories.badges,
             title: `Tavis | Award Badge`,
           },
         },
         ...createRoute({
           category: RouteCategories.notificationSettings,
           name: 'notification_settings',
           listPage: {
             path: `user_notifications/UserNotificationTypes.vue`,
           },
           createPage: {
             path: `user_notifications/AddUserNotificationTypes.vue`,
           },
           editPage: {
             path: `user_notifications/EditUserNotificationTypes.vue`,
           },
         }),
         ...createRoute({
           category: RouteCategories.notificationBatches,
           name: 'notification_batches',
           listPage: {
             path: `user_notifications/UserNotificationBatches.vue`,
           },
           createPage: {
             path: `user_notifications/AddUserNotificationBatches.vue`,
           },
           editPage: {
             path: `user_notifications/EditUserNotificationBatches.vue`,
           },
         }),
       ];

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'active',
});

const loginStore = UserStores.login;
const userProfileStore = UserStores.profile;
router.beforeEach(async (to, _, next) => {
  document.title = to.meta?.title ? to.meta.title : `Tavis`;
  let isAuthenticated = false;
  if (to.path !== '/reset_password' && to.path !== '/reset_password_success') {
    isAuthenticated = await loginStore.verifyAuth();
  }

  if (isAuthenticated && userProfileStore.profileRole.length === 0) {
    await userProfileStore.retrieveUserProfile();
  }

  if (to.meta!.requiresAuth && !isAuthenticated) {
    next({ name: `login` });
  } else if (to.meta!.requiresAuth && isAuthenticated && to.meta!.roles) {
    // Check Role
    const isValid = to.meta!.roles.some((role: string) => {
      return role === userProfileStore.profileRole;
    });

    if (isValid) {
      next();
    } else if (
      userProfileStore.profileRole === 'student' ||
      userProfileStore.profileRole.length === 0
    ) {
      ToastProgrammatic.open({
        duration: 3000,
        message: `User does not have sufficient permission to enter`,
        type: `is-black`,
      });
      userProfileStore.setProfileRole(``);
      next({ name: `login` });
    } else {
      ToastProgrammatic.open({
        duration: 3000,
        message: `Redirecting to Timetable. User does not have sufficient permission to enter`,
        type: `is-black`,
      });
      next({ name: `timetable` });
    }
  } else {
    next();
  }
});

export default router;
