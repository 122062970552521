import UserLoginStore from './user.login';
import UserCreateStore from './user.create';
import UserDetailStore from './user.detail';
import UserListStore from './user.list';
import UserProfileStore from './user.profile';
import UserStateStore from './user.state';

export const UserStores = {
  login: UserLoginStore,
  list: UserListStore,
  create: UserCreateStore,
  detail: UserDetailStore,
  profile: UserProfileStore,
  state: UserStateStore,
};
