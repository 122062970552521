import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { UserLoginRequest } from '@/models/users';
import { LoginResponse } from '@/models/login';
import { Roles } from '@/utils/constants';

@Module({
    namespaced: true,
    dynamic: true,
    name: `users.login`,
    store,
})
class UserLoginStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: any | null = null; // TODO: Replace this with a better interface when we have an API
    isLoggedIn: boolean = false;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: any) {
        this.response = payload;
    }

    @Mutation
    setIsLoggedIn(payload: boolean) {
        this.isLoggedIn = payload;
    }

    @Action 
    loginUser(payload: UserLoginRequest) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        axios.post<LoginResponse>(`/auth/manualLogin`, payload).then((response) => {
            if (response.data.code === 200 && (response.data.data.role === Roles.Teacher || response.data.data.role === Roles.Admin)) {
                this.context.commit(`setResponse`, response.data.data);
                this.context.commit(`setIsLoggedIn`, true);
            }

            if (response.data.code === 200 && response.data.data.role !== Roles.Teacher && response.data.data.role !== Roles.Admin) {
                this.context.commit(`setIsLoggedIn`, false);
                Toast.open({
                    type: `is-dark`,
                    duration: 5000, // 3 Seconds
                    message: `Invalid Access`,
                });
            }
            
            if (response.data.code === 403 || response.data.code === 500) {
                this.context.commit(`setIsLoggedIn`, false);
                Toast.open({
                    type: `is-danger`,
                    duration: 5000, // 3 Seconds
                    message: `Invalid Credentials. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
            this.context.commit(`setIsLoggedIn`, false);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    async verifyAuth() {
        if (this.isLoggedIn) {
            return this.isLoggedIn;
        }
        const response = await axios.get(`/auth/me`);
        if (response.data && response.data.code === 200) {
            this.context.commit(`setIsLoggedIn`, true);
        } else {
            this.context.commit(`setIsLoggedIn`, false);
        }
        return this.isLoggedIn;
    }
}

export default getModule(UserLoginStore);