// SCSS Imports
import '@/assets/scss/config.scss';
import '@/assets/scss/contents/_buefy_base.scss';
// Regular Imports
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import Buefy from 'buefy';
import vueDebounce from 'vue-debounce';
import { ValidationProvider, extend } from 'vee-validate';
import { required, email, numeric } from 'vee-validate/dist/rules';

Vue.use(Buefy, {
  defaultIconPack: 'fas',
});
Vue.config.productionTip = false;
Vue.use(vueDebounce, { defaultTime: '1000ms' });

Vue.component('ValidationProvider', ValidationProvider);
extend('email', {
  ...email,
});
extend('required', {
  ...required,
  message: 'This field is required',
});
extend('url', {
  validate(value: string | null | undefined): boolean {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        value
      );
    }
    return false;
  },
  message: 'This field must be a valid URL',
});
extend('numeric', {
  ...numeric,
  message: 'This field must be a number',
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
