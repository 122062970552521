import { UserStores } from '@/store/users';
import Axios, { AxiosRequestConfig } from 'axios';

enum HTTPStatus {
  Unauthorized = 401,
}

const axiosConfig: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 20000, // 20 seconds
  withCredentials: true,
};

const axiosInstance = Axios.create(axiosConfig);
axiosInstance.interceptors.response.use(
  (response) => {
    // Response interceptor
    return response;
  },
  (error) => {
    // Error interceptor
    if (error.response && error.response.data.code === HTTPStatus.Unauthorized) {
      UserStores.login.setIsLoggedIn(false);
    }
    // For time out errors, we will need to just return axios's error
    if (error.code === 'ECONNABORTED') {
      return error;
    }
    // Have to expose it like this due to error details being a level lower
    return error.response;
  }
);

export const axios = axiosInstance;
