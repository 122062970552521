import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { UserDetailedItemResponse } from '@/models/users';

@Module({
  namespaced: true,
  dynamic: true,
  name: `user.profile`,
  store,
})
class UserProfileStore extends VuexModule {
  isLoading = false;
  error: any | null = null;
  profileRole: string = '';
  profileName: string = '';
  profileDetails: Partial<UserDetailedItemResponse> = {};

  @Mutation
  setError(payload: any) {
    this.error = payload;
  }

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setProfileRole(payload: string) {
    this.profileRole = payload;
  }

  @Mutation
  setProfileName(payload: string) {
    this.profileName = payload;
  }

  @Mutation
  setProfileDetails(payload: Partial<UserDetailedItemResponse>) {
    this.profileDetails = payload;
  }

  @Action
  async retrieveUserProfile() {
    const response = await axios.get(`/auth/me`);
    if (response.data && response.data.code === 200) {
      const profileDetails: Partial<UserDetailedItemResponse> = {
        title_id: response.data.data.objects.title_id,
        title: response.data.data.objects.title,
        firstname: response.data.data.objects.firstname,
        lastname: response.data.data.objects.lastname,
        email: response.data.data.objects.email,
        mobile: response.data.data.objects.mobile,
        birthday: response.data.data.objects.birthday,
        role: response.data.data.objects.role.name,
        address: response.data.data.objects.address,
        address2: response.data.data.objects.address2,
        city: response.data.data.objects.city,
        state: response.data.data.objects.state,
        postcode: response.data.data.objects.postcode,
        profile_url: response.data.data.objects.profile_url,
        // Teacher
        degree: response.data.data.objects.degree,
        university: response.data.data.objects.university,
        gender: response.data.data.objects.gender,
      };
      this.context.commit(
        `setProfileRole`,
        response.data.data.objects.role ? response.data.data.objects.role.name : ''
      );
      this.context.commit(
        `setProfileName`,
        `${response.data.data.objects.firstname} ${response.data.data.objects.lastname}`
      );
      this.context.commit(`setProfileDetails`, profileDetails);
    }
  }
}

export default getModule(UserProfileStore);
