import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  UserDetailedItemResponse,
  UserListResponse,
  UpdateUserForAxios,
  UserRetriveResponse,
  TitleListResponse,
  TitleResponseDataObject,
} from '@/models/users';

@Module({
  namespaced: true,
  dynamic: true,
  name: `user.detail`,
  store,
})
class UserDetailStore extends VuexModule {
  isLoading = false;
  error: any | null = null;
  response: boolean | null = null;
  fetchedUserResponse: UserDetailedItemResponse | null = null;
  fetchedTitleResponse: TitleResponseDataObject | null = null;
  titleResponse: boolean | null = null;
  userId: number = -1;
  userData: UpdateUserForAxios | null = null;

  @Mutation
  setError(payload: any) {
    this.error = payload;
  }

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setResponse(payload: boolean) {
    this.response = payload;
  }

  @Mutation
  setUserDataResponse(payload: UserDetailedItemResponse) {
    this.fetchedUserResponse = payload;
  }

  @Mutation
  setUserId(payload: number) {
    this.userId = payload;
  }

  @Mutation
  setUserData(payload: UpdateUserForAxios) {
    this.userData = payload;
  }

  @Mutation
  setTitleResponse(payload: TitleResponseDataObject) {
    this.fetchedTitleResponse = payload;
  }

  @Action
  retrieveUserById(payload: number) {
    this.context.commit(`updateLoadingStatus`, true);
    this.context.commit(`setError`, null);
    this.context.commit(`setUserDataResponse`, null);
    this.context.commit(`setUserId`, payload);
    axios.get<UserRetriveResponse>(`/user/${payload}`).then((response) => {
      if (response.data.code === 200) {
        this.context.commit(`setUserDataResponse`, response.data.data.objects);
      }

      if (response.data.code === 500) {
        Toast.open({
          type: `is-danger`,
          duration: 3000, // 3 Seconds
          message: `There is an issue with retrieving the specified user. Please try again`,
        });
      }
    });

    this.context.commit(`updateLoadingStatus`, false);
  }

  @Action
  updateUserById(payload: Partial<UpdateUserForAxios>) {
    this.context.commit(`updateLoadingStatus`, true);
    this.context.commit(`setError`, null);
    this.context.commit(`setResponse`, null);
    this.context.commit(`setUserData`, payload);

    const axiosPayload: Partial<UpdateUserForAxios> = {
      title_id: payload.title_id as number,
      firstname: payload.firstname as string,
      lastname: payload.lastname as string,
      email: payload.email as string,
      password: payload.password as string,
      force_update_password: payload.force_update_password as boolean,
      mobile: payload.mobile as string,
      role: payload.role as string,
      birthday: payload.birthday as string,
      address: payload.address as string,
      address2: payload.address2 as string,
      city: payload.city as string,
      state: payload.state as string,
      postcode: payload.postcode as string,
      gender: payload.gender as string,
      degree: payload.degree as string,
      university: payload.university as string,
    };
    axios
      .put<UserListResponse>(`/user/${payload.id}`, axiosPayload)
      .then((response) => {
        if (response.data.code === 200) {
          this.context.commit(`setResponse`, response.data.data);
          Toast.open({
            type: `is-success`,
            duration: 3000, // 3 Seconds
            message: `User has been successfully updated`,
          });
        }

        if (response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with updating the current user. Please try again`,
          });
        }
      })
      .catch((error) => {
        this.context.commit(`setError`, error);
      });
    this.context.commit(`updateLoadingStatus`, false);
  }

  @Action
  deleteUserById(payload: number) {
    this.context.commit(`updateLoadingStatus`, true);
    this.context.commit(`setError`, null);
    this.context.commit(`setResponse`, null);
    this.context.commit(`setUserId`, payload);
    axios
      .delete<UserListResponse>(`/user/${payload}`)
      .then((response) => {
        if (response.data.code === 200) {
          this.context.commit(`setResponse`, response.data.data);
          Toast.open({
            type: `is-success`,
            duration: 3000, // 3 Seconds
            message: `User has been successfully deleted`,
          });
        } else {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with deleting the user. Please try again`,
          });
        }
      })
      .catch((error) => {
        this.context.commit(`setError`, error);
      });
    this.context.commit(`updateLoadingStatus`, false);
  }

  @Action
  retrieveTitles() {
    this.context.commit(`updateLoadingStatus`, true);
    this.context.commit(`setError`, null);
    this.context.commit(`setTitleResponse`, null);

    axios.get<TitleListResponse>(`/masterdata/titles`).then((response) => {
      if (response.data.code === 200) {
        this.context.commit(`setTitleResponse`, response.data.data);
      }

      if (response.data.code === 500) {
        Toast.open({
          type: `is-danger`,
          duration: 3000, // 3 Seconds
          message: `There is an issue with retrieving the specified user. Please try again`,
        });
      }
    });

    this.context.commit(`updateLoadingStatus`, false);
  }
}

export default getModule(UserDetailStore);
