import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { CreateUserForAxios } from '@/models/users';
import { UserListResponse } from '@/models/users';

@Module({
  namespaced: true,
  dynamic: true,
  name: `user.create`,
  store,
})
class UserCreateStore extends VuexModule {
  isLoading = false;
  error: any | null = null;
  response: boolean | null = null;
  userProperties: CreateUserForAxios | null = null;

  @Mutation
  setError(payload: any) {
    this.error = payload;
  }

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setResponse(payload: boolean | null) {
    this.response = payload;
  }

  @Mutation
  setUserProperties(payload: CreateUserForAxios) {
    this.userProperties = payload;
  }

  @Action
  createNewUser(payload: Partial<CreateUserForAxios>) {
    this.context.commit(`updateLoadingStatus`, true);
    this.context.commit(`setError`, null);
    this.context.commit(`setResponse`, null);
    this.context.commit(`setUserProperties`, payload);

    axios
      .post<UserListResponse>(`/user`, payload)
      .then((response) => {
        if (response.data.code === 200) {
          this.context.commit(`setResponse`, response.data.data);
          Toast.open({
            type: `is-success`,
            duration: 3000, // 3 Seconds
            message: `Successfully created User`,
          });
        }

        if (response.data.code === 500 && response.data.error?.includes(`This Email is already used, choose another`)) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `Email has been used to create a user. Please use a new email`,
          });
        } else if (response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with creating a User. Please try again`,
          });
        }
      })
      .catch((error) => {
        this.context.commit(`setError`, error);
      });
    this.context.commit(`updateLoadingStatus`, false);
  }
}

export default getModule(UserCreateStore);
