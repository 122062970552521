import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { UserDataResponse, UserListFetchParams, UserListResponse } from '@/models/users';

@Module({
  namespaced: true,
  dynamic: true,
  name: `user.list`,
  store,
})
class UserListStore extends VuexModule {
  isLoading = false;
  error: any | null = null;
  response: UserDataResponse | null = null;
  keyword: string = '';
  sortby: string = '';
  sortorder: string = '';
  filter: string = '';

  @Mutation
  setError(payload: any) {
    this.error = payload;
  }

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setResponse(payload: UserDataResponse) {
    this.response = payload;
  }

  @Mutation
  setSortby(payload: string) {
    this.sortby = payload;
  }

  @Mutation
  setSortorder(payload: string) {
    this.sortorder = payload;
  }

  @Mutation
  setKeyword(payload: string) {
    this.keyword = payload;
  }

  @Mutation
  setFilter(payload: string) {
    this.filter = payload;
  }

  @Action
  retrieveUsers(params?: Partial<UserListFetchParams>) {
    this.context.commit(`updateLoadingStatus`, true);
    this.context.commit(`setError`, null);
    this.context.commit(`setResponse`, null);

    this.context.commit(`setSortby`, params?.sortby ? params.sortby : '');
    this.context.commit(`setSortorder`, params?.sortorder ? params.sortorder : '');
    this.context.commit(`setKeyword`, params?.keyword ? params.keyword : '');
    this.context.commit(`setFilter`, params?.filter ? params.filter : '');

    axios
      .get<UserListResponse>(`/user`, {
        params: params,
      })
      .then((response) => {
        if (
          response.data.code === 200 && 
          typeof response.data.data !== 'boolean' &&
          'totalCount' in response.data.data
        ) {
          response.data.data.totalCount = response.headers[`x-total-records`];
          this.context.commit(`setResponse`, response.data.data);
        }

        if (response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with fetching user data. Please try again`,
          });
        }
      })
      .catch((error) => {
        this.context.commit(`setError`, error);
      });
    this.context.commit(`updateLoadingStatus`, false);
  }
}

export default getModule(UserListStore);
