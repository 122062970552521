







import { Component, Vue } from 'vue-property-decorator';
import { axios } from './configs/axios';

@Component({
  components: {},
})
export default class App extends Vue {
  requestCount = 0;

  get isLoaderActive() {
    return this.requestCount > 0;
  }

  created() {
    axios.interceptors.request.use((config) => {
      this.requestCount++;
      return config;
    });
    axios.interceptors.response.use(
      (response) => {
        this.requestCount--;
        return response;
      },
      (error) => {
        this.requestCount--;
        return error;
      }
    );
  }
}
